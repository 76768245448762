import './scss/_variables.scss';
import './scss/normalize.scss';
import './scss/app.scss';
import AOS from 'aos';
import Swiper, { Navigation, Pagination } from 'swiper';
import 'bootstrap';
import 'vanilla-cookieconsent';

AOS.init({
    offset: 200,
    delay: 100,
    duration: 1000,   
    once: true,
});

const swiper = new Swiper('.swiper', {
    // configure Swiper to use modules
    modules: [Navigation, Pagination],
    speed: 400,
    spaceBetween: 2,
    // autoHeight: true,

      // Default parameters
    slidesPerView: 1,
    // Responsive breakpoints
    // breakpoints: {
    //     // when window width is >= 320px
    //     320: {
    //     slidesPerView: 2,
    //     spaceBetween: 20
    //     },
    //     // when window width is >= 480px
    //     480: {
    //     slidesPerView: 3,
    //     spaceBetween: 30
    //     },
    //     // when window width is >= 640px
    //     640: {
    //     slidesPerView: 4,
    //     spaceBetween: 40
    //     }
    // },

    
    /** initialize slider */
    // Optional parameters
    direction: 'horizontal',
    loop: true,

    // Disable preloading of all images
    // preloadImages: false,
    // Enable lazy loading
    // lazy: true,

    // If we need pagination
    pagination: {
    	el: '.swiper-pagination',
    },

    // Navigation arrows
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },

});

function cookieShow(){
    cc.show(0);
};